<script setup>

</script>

<template>
    <section>
        <div class="team" id="team" >
            <h3 class="team__title" data-aos="text">
                Team
            </h3>
            <div class="team__cards">

                <div class="team__cards__item">
                    <div class="bomb-card bomb-card--turqoise-shadow" data-aos="block">
                        <img class="bomb-card__image" src="../assets/Bombs/showcase_green.png" alt="Bomb Buddies NFT">
                        <h6 class="bomb-card__name">
                            Vert
                        </h6>
                        <div class="bomb-card__role">
                            Big man
                        </div>
                    </div>
                </div>
                <div class="team__cards__item">
                    <div class="team__cards__item bomb-card bomb-card--turqoise-shadow" data-aos="block">
                        <img class="bomb-card__image" src="../assets/Bombs/showcase_green.png" alt="Bomb Buddies NFT">
                        <h6 class="bomb-card__name">
                            Vert
                        </h6>
                        <div class="bomb-card__role">
                            Big man
                        </div>
                    </div>
                </div>
                <div class="team__cards__item">
                    <div class="team__cards__item bomb-card bomb-card--turqoise-shadow" data-aos="block">
                        <img class="bomb-card__image" src="../assets/Bombs/showcase_green.png" alt="Bomb Buddies NFT">
                        <h6 class="bomb-card__name">
                            Timithy V
                        </h6>
                        <div class="bomb-card__role">
                            Big man
                        </div>
                    </div>
                </div>
                <div class="team__cards__item">
                    <div class="team__cards__item bomb-card bomb-card--turqoise-shadow" data-aos="block">
                        <img class="bomb-card__image" src="../assets/Bombs/showcase_green.png" alt="Bomb Buddies NFT">
                        <h6 class="bomb-card__name">
                            Timithy V
                        </h6>
                        <div class="bomb-card__role">
                            Big man
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>

<style scoped>

    [data-aos="text"] {
        transition: all .3s .3s;
    }

    [data-aos="text"]:not(.aos-animate) {
        transform: translateY(23px);
        opacity: 0;
    }

    [data-aos="block"] {
        transition: all .6s;
    }

    [data-aos="block"]:not(.aos-animate) {
        /* opacity: .3; */
        transform: rotateZ(6deg);
    }

    .team {
        margin-top: 220px; /* Not margin, padding so the background extends up. */
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: var(--max-width);
        scroll-margin-top: 20vh;
    }

    .team__title {
        text-transform: uppercase;
        color: hsl(var(--clr-black));
        margin-bottom: 78px;
    }

    .team__cards {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .team__cards__item {
        flex-basis: 33.333%;
        height: 400px;
    }

    .team__cards__item:nth-child(3n-1) {
        display: flex;
        justify-content: center;
    }

    .team__cards__item:nth-child(3n) {
        display: flex;
        justify-content: flex-end;
    }

    .bomb-card {
        width: fit-content;
        height: fit-content;
        border-radius: var(--border-radius);
        transform: rotateZ(6deg);
        transform-origin: center;
        transition: all .3s;
        border: dashed 4px transparent;
        /* animation: border-animation .6s infinite ease;
        animation-play-state: paused; */
    }

    /* @keyframes border-animation {
        50% {
            border: dashed 4px hsl(var(--clr-turqoise));
        }
    } */

    .bomb-card:hover {
        transform: rotateZ(0);
        /* border: dashed 4px hsla(var(--clr-turqoise), .3); */
        /* cursor: pointer; */
        /* animation-play-state: running; */
    }

    /* .bomb-card:active {
        transform: perspective(700px) translateZ(-50px);
    } */

    .bomb-card--turqoise-shadow {
        box-shadow: 0 0 25px 13px hsla(var(--clr-turqoise), .35);
    }

    .bomb-card__image {
        border: solid calc(var(--border-radius) / 2) hsl(var(--clr-base));
        border-radius: var(--border-radius);
        border-image-width: 0;
        height: 280px;
        width: 280px;
    }

    .bomb-card__name {
        color: hsl(var(--clr-black));
        text-align: left;
        padding-left: var(--border-radius);
        padding-right: var(--border-radius);
        text-transform: uppercase;
        font-weight: 900;
        height: 0;
        overflow: hidden;
        transition: all .3s;
    }
    
    .bomb-card:hover .bomb-card__name {
        height: 2ch;
    }

    .bomb-card__role {
        color: hsla(var(--clr-black), .3);
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        padding-left: var(--border-radius);
        padding-right: var(--border-radius);
        margin-bottom: 0;
        height: 0;
        overflow: hidden;
        transition: all .3s;
    }

    .bomb-card:hover .bomb-card__role {
        height: 2ch;
        padding-bottom: 60px;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .team__cards__item {
            flex-basis: 50%;
        }

        .team__cards__item:nth-child(3n-1) {
            display: inline-block;
            justify-content: unset;
        }

        .team__cards__item:nth-child(3n) {
            display: inline-block;
            justify-content: unset;
        }

        .team__cards__item:nth-child(2n) {
            display: flex;
            justify-content: flex-end;
        }

    }


    @media only screen and (min-width: 320px) and (max-width: 768px) {

        .team__cards {
            flex-direction: column;
        }

        .team__cards__item:not(:last-child) {
            margin-bottom: 30px;
            height: auto;
        }

        .team__cards__item:nth-child(3n-1) {
            display: inline-block;
            justify-content: unset;
        }

        .team__cards__item:nth-child(3n) {
            display: inline-block;
            justify-content: unset;
        }

        .team__cards__item {
            flex-basis: unset;
        }

        .bomb-card__image {
            height: 240px;
            width: 240px;
        }

        

    }




</style>