<script setup>

</script>

<template>
    <div class="overlay" data-aos="overlay">
        <img src="../assets/Foreground/Foreground_Left.svg" class="overlay__image overlay__image--left">
        <img src="../assets/Foreground/Foreground_Right.svg" class="overlay__image overlay__image--right">
    </div>
</template>

<style scoped>
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        pointer-events: none;
        z-index: 1;
        transition: all 1.3s .6s;
    }

    [data-aos="overlay"]:not(.aos-animate) {
        opacity: 0;
    }

    .overlay__image {
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
    }

    .overlay__image--left {
        left: 0;
    }

    .overlay__image--right {
        right: 0;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .overlay {
            display: none;
        }

    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {

        .overlay {
            display: none;
        }

    }


</style>