<template>
    <IconBase :width="width" :height="height" viewBox="0 0 38 30" icon-name="Discord">
      <g transform="matrix(1,0,0,1,-10468.5,-6407.89)">
          <g transform="matrix(1,0,0,6.42265,9144.05,0)">
              <g transform="matrix(0.516858,0,0,0.0804742,1324.42,996.755)">
                  <path d="M61.796,16.494C57.074,14.285 52.024,12.679 46.746,11.765C46.097,12.937 45.34,14.513 44.818,15.767C39.206,14.923 33.646,14.923 28.138,15.767C27.616,14.513 26.841,12.937 26.187,11.765C20.903,12.679 15.848,14.291 11.126,16.506C1.601,30.899 -0.981,44.934 0.31,58.771C6.627,63.488 12.749,66.354 18.768,68.229C20.254,66.184 21.58,64.01 22.722,61.719C20.547,60.892 18.464,59.873 16.496,58.689C17.018,58.302 17.529,57.898 18.022,57.481C30.026,63.096 43.068,63.096 54.928,57.481C55.427,57.898 55.938,58.302 56.454,58.689C54.48,59.878 52.392,60.898 50.217,61.724C51.359,64.01 52.679,66.19 54.17,68.235C60.195,66.36 66.323,63.494 72.64,58.771C74.155,42.731 70.053,28.824 61.796,16.494ZM24.357,50.262C20.754,50.262 17.799,46.898 17.799,42.801C17.799,38.705 20.69,35.335 24.357,35.335C28.023,35.335 30.978,38.699 30.915,42.801C30.921,46.898 28.023,50.262 24.357,50.262ZM48.593,50.262C44.99,50.262 42.035,46.898 42.035,42.801C42.035,38.705 44.927,35.335 48.593,35.335C52.26,35.335 55.215,38.699 55.152,42.801C55.152,46.898 52.26,50.262 48.593,50.262Z" style="fill-rule:nonzero;"/>
              </g>
          </g>
      </g>
    </IconBase>
</template>

<script>
import IconBase from "./IconBase"

  export default {
    props: {
      width: {type: [Number, String],default: 20},
      height: {type: [Number, String],default: 20},
      iconColor: {type: String, default: 'currentColor'}
    },
    components: {
        IconBase
    }
  }
</script>