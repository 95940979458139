<script setup>
import ButtonPrimaryVue from './Buttons/ButtonPrimary.vue';
import ButtonSecondaryVue from './Buttons/ButtonSecondary.vue';

import { ref, onMounted } from 'vue';
import AOS from 'aos';
import { animate } from "motion"

const background = ref(0);


onMounted(() => {
    // Background Animation
    animate(background.value, { 
        backgroundPosition: `
            center calc(100% + 1px),
            ${background.value.offsetWidth/3 - 20}px bottom,
            center ${background.value.offsetHeight}px
        `
    }, { 
        repeat: Infinity, 
        duration: 5.2,
        easing: "linear"
    })

    // Animate - On - Scroll Init
    AOS.init({mirror: true})
})

</script>

<template>
    <section ref="background">
        <div class="hero">
            <div class="hero__content">
                <h1 class="hero__content__title" data-aos="text">
                    Bomb Buddies NFT
                </h1>
                <h6 class="hero__content__description" data-aos="text">
                    Community-driven, art focused utility-centered nft project.
                </h6>
                <div class="hero__content__cta">
                    <a href="#">
                        <ButtonPrimaryVue text="Secure WL" data-aos="button" />
                    </a>
                    <a href="#about">
                        <ButtonSecondaryVue text="Learn More" data-aos="button" />
                    </a>
                    
                </div>
            </div>
        </div>
        <div class="stats">

            <div class="stats__item stats__item--blue" data-aos="block">
                <div>
                    <h4 class="stats__item__main">
                        5,555
                    </h4>
                    <p class="stats__item__secondary">Bombs</p>                    
                </div>
            </div>

            <div class="stats__item stats__item--green" data-aos="block">
                <div>
                    <h4 class="stats__item__main">
                        TBA
                    </h4>
                    <p class="stats__item__secondary">Mint Price</p>                    
                </div>
            </div>

            <div class="stats__item stats__item--red" data-aos="block">
                <div>
                    <h4 class="stats__item__main">
                        90%
                    </h4>
                    <p class="stats__item__secondary">Phase 1 Progress</p>
                    <p class="stats__item__highlight">YOU'RE EARLY.</p>                    
                </div>
            </div>

        </div>
    </section>
</template>

<style scoped>

    /* General AOS Animations */

    [data-aos="text"] {
        transition: all .3s;
    }

    [data-aos="text"]:not(.aos-animate) {
        transform: translateY(23px);
        opacity: 0;
    }

    [data-aos="button"] {
        transition: all .3s;
    }

    [data-aos="button"]:not(.aos-animate) {
        transform: perspective(700px) translateZ(-500px);
        opacity: 0;
    }

    [data-aos="block"] {
        transition: all .6s;
    }

    [data-aos="block"]:not(.aos-animate) {
        opacity: .3;
    }


    section {
        background-image:
            url(../assets/Background/Background_Transition_Curved.svg),
            url(../assets/Background/Background_Hollow_BB_Text.png),
            url(../assets/Background/Background_Holographic_Space_Repeat.svg);
        background-repeat: 
            no-repeat,
            repeat-x,
            repeat-y;
        background-size:
            contain,
            33.33% auto,
            cover;
        background-position:
            center calc(100% + 1px),
            -20px bottom,
            center 0;
        min-height: calc(100vh - 20px);
    }

    .hero {
        padding-top: 140px; /* Not margin, padding so the background extends up. */
        margin-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: var(--max-width);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-height: calc(100vh - 180px);
    }

    .hero__content {
        /* background-color: red; */
        padding-top: var(--margin);
        padding-bottom: var(--margin);
        padding-left: var(--margin);
        padding-right: var(--margin);
        text-align: left;
    }

    .hero__content__title {
        margin-bottom: 31px;
    }

    .hero__content__description {
        font-weight: 500;
        max-width: 25ch;
        line-height: 1.4;
        margin-bottom: 51px;
    }

    .hero__content__cta {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .stats {
        padding-top: 0px; /* Not margin, padding so the background extends up. */
        margin-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        min-height: 50px;
        max-width: var(--max-width);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        --child-width: calc(var(--max-width)/3 - var(--margin)/2);
    }

    .stats__item {
        height: 120px;
        width: var(--child-width);
        background-color: hsl(var(--clr-base));
        border-radius: var(--border-radius);
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .stats__item--blue {
        color: hsl(var(--clr-blue));
        box-shadow: 0 0 25px 13px hsla(var(--clr-blue), .35);
    }

    .stats__item--red {
        color: hsl(var(--clr-red));
        box-shadow: 0 0 25px 13px hsla(var(--clr-red), .35);
    }

    .stats__item--green {
        color: hsl(var(--clr-green));
        box-shadow: 0 0 25px 13px hsla(var(--clr-green), .35);
    }

    .stats__item__main {
        font-weight: 900;
        text-align: left;
    }

    .stats__item__secondary {
        line-height: 1;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
    }

    .stats__item__highlight {
        position: absolute;
        top: calc(100% + 10px);
        right: 50%;
        transform: translateX(50%);
        font-size: var(--fs-h4);
        font-weight: 900;
        white-space: nowrap;
        opacity: .3;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .hero__content {
            padding-left: 0;
            padding-right: 0;
        }

        .stats {
            max-width: 100%;
            justify-content: space-evenly;
            --child-width: 30% ;
        }

        .stats__item__highlight {
            right: 0;
            transform: translateX(0);
        }

    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {

        section {
            background-image:
                url(../assets/Background/Background_Transition_Curved.svg),
                linear-gradient(transparent, transparent), /* Invisible layer for simple js animation compatibility */
                url(../assets/Background/Background_Holographic_Space_Repeat.svg);
            background-repeat: 
                no-repeat,
                no-repeat,  /* Invisible layer for simple js animation compatibility */
                repeat-y;
            background-size:
                auto 50%,
                0,  /* Invisible layer for simple js animation compatibility */
                cover;
            background-position:
                center calc(100% + 1px),
                0,  /* Invisible layer for simple js animation compatibility */
                center 0;
        }

        .hero__content {
            padding-left: 0;
            padding-right: 0;
        }

        .hero__content__cta {
            align-items: flex-start;
            flex-direction: column;
        }

        .hero__content__cta a {
            margin-bottom: 13px;
        }

        .stats {
            flex-direction: column;
            --child-width: 100% ;
        }

        .stats__item {
            margin-bottom: 26px;
        }

        .stats__item:last-child {
            margin-bottom: 0;
        }

        [data-aos="block"]:not(.aos-animate).stats__item--blue {
            opacity: 1;
        }


    }




</style>