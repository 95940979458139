<template>
    <IconBase :width="width" :height="height" viewBox="0 0 24 24" icon-name="Menu">
      <rect x="0" y="0" width="24" height="24" style="fill:none;fill-rule:nonzero;"/>
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill-rule:nonzero;"/>
    </IconBase>
</template>

<script>
import IconBase from "./IconBase"

  export default {
    props: {
      width: {type: [Number, String],default: 20},
      height: {type: [Number, String],default: 20},
      iconColor: {type: String, default: 'currentColor'}
    },
    components: {
        IconBase
    }
  }
</script>