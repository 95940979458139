<template>
    <IconBase :width="width" :height="height" viewBox="0 0 12 12" icon-name="Arrow Down Right">
      <g transform="matrix(1,0,0,1,-100529,-3836.22)">
        <g transform="matrix(1,0,0,7.32673,100010,2232.6)">
            <g transform="matrix(0.687431,0.0938251,-0.687431,0.0938251,525.186,217.465)">
                <path d="M12,4L10.59,5.41L16.17,11L4,11L4,13L16.17,13L10.59,18.59L12,20L20,12L12,4Z" style="fill-rule:nonzero;"/>
            </g>
        </g>
      </g>
    </IconBase>
</template>

<script>
import IconBase from "./IconBase"

  export default {
    props: {
      width: {type: [Number, String],default: 20},
      height: {type: [Number, String],default: 20},
      iconColor: {type: String, default: 'currentColor'}
    },
    components: {
        IconBase
    }
  }
</script>