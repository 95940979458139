<script setup>

</script>

<template>
    <div class="footer">
        <div class="footer__branding">
            <img class="footer__branding__logo" src="../assets/Logo.svg">
        </div>
        <div class="footer__copyright">
            &#169;2022 Bomb Buddies NFT. All rights reserved.
        </div>
    </div>
</template>

<style scoped>

    .footer {
        margin-top: min(25vh, 200px);
        margin-left: auto;
        margin-right: auto;
        background-color: hsla(var(--clr-base), .3);
        height: 100px;
        width: 100%;
        max-width: var(--max-width);
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer__branding {
        width: calc(var(--margin)*2);
        height: 100px;
        position: relative;
    }

    .footer__branding__logo {
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: invert(100%);
    }

    .footer__copyright {
        width: calc(var(--margin)*2);
        font-size: var(--fs-small);
        text-align: right;
        padding-right: calc(var(--margin)/2);
        font-weight: 500;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .footer__copyright {

        }

    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {

        .footer {
            margin-top: 0;
            justify-content: center;
        }

        .footer__branding {
            display: none;
        }

        .footer__copyright {
            text-align: center;
            padding-right: 0;
        }

    }

</style>