<script setup>
import ButtonPrimaryVue from './Buttons/ButtonPrimary.vue';
import ButtonPrimaryDiscordVue from './Buttons/ButtonPrimaryDiscord.vue';
import ButtonPrimaryTwitterVue from './Buttons/ButtonPrimaryTwitter.vue';
import FooterVue from './Footer.vue';

import { ref, onMounted } from 'vue';
import { animate } from "motion"

const background = ref(0);


onMounted(() => {
    // Background Animation
    animate(background.value, { 
        backgroundPosition: `
            center -1px,
            0 ${background.value.offsetHeight}px
        `
    }, { 
        repeat: Infinity, 
        duration: 4.6,
        easing: "linear"
    })

})


</script>

<template>
    <section ref="background">
        <div></div>
        <div class="cta">
            <div class="cta__text-content">
                <h2 class="cta__text-content__title" data-aos="text">
                    Get Started
                </h2>
                <h6 class="cta__text-content__description" data-aos="text">
                    Hand out OGs to active members within our community. I'm bad with copy + brain dead rn, you guys figure this out.
                </h6>
            </div>
            <div class="cta__buttons">
                <a href="#" class="cta__button">
                    <ButtonPrimaryVue text="Secure WL" data-aos="button" />
                </a>
                <a href="#" class="cta__button">
                    <ButtonPrimaryDiscordVue text="Discord" data-aos="button" />
                </a>
                <a href="#" class="cta__button">
                    <ButtonPrimaryTwitterVue text="Twitter" data-aos="button" />
                </a>
            </div>
        </div>
        <FooterVue />
    </section>
</template>

<style scoped>

    /* General AOS Animations */

    [data-aos="text"] {
        transition: all .3s;
    }

    [data-aos="text"]:not(.aos-animate) {
        transform: translateY(23px);
        opacity: 0;
    }

    [data-aos="button"] {
        transition: all .3s;
    }

    [data-aos="button"]:not(.aos-animate) {
        transform: perspective(700px) translateZ(-500px);
        opacity: 0;
    }


    section {
        background-image:
            url(../assets/Background/Background_Transition_Curved_Flipped.svg),
            /* url(../assets/Background/Background_Hollow_BB_Text.png), */
            url(../assets/Background/Background_Holographic_Space_Repeat.svg);
        background-repeat: 
            no-repeat,
            /* no-repeat, */
            repeat-y;
        background-size:
            contain,
            /* contain, */
            cover;
        background-position:
            center -1px,
            /* -20px bottom, */
            0 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .cta {
        padding-top: 400px; /* Not margin, padding so the background extends up. */
        margin-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: var(--max-width);
        padding-right: var(--margin);
        padding-left: var(--margin);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cta__text-content {
        text-align: left;
    }

    .cta__text-content__title {
        width: max-content;
        text-transform: uppercase;
        margin-bottom: 23px;
    }

    .cta__text-content__description {
        max-width: 35ch;
        font-weight: 500;
        line-height: 1.4;
    }

    .cta__buttons {
        display: flex;
        flex-direction: column;
    }

    .cta__button {
        display: inline-block;
    }

    .cta__button:not(:last-child) {
        margin-bottom: 13px;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .cta {
            padding-top: 300px;
            flex-direction: column;
            align-items: flex-start;
        }

        .cta__text-content {
            margin-bottom: 30px;
        }

    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {

        .cta {
            padding-top: 200px;
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 150px;
            flex-direction: column;
            align-items: flex-start;
        }

        .cta__text-content {
            margin-bottom: 30px;
        }

        .cta__text-content__title {
            width: unset;
        }

        .cta__text-content__description {
            max-width: unset;
        }

    }

</style>