<template>
    <IconBase :width="width" :height="height" viewBox="0 0 23 18" icon-name="Twitter">
        <g transform="matrix(1,0,0,1,-6766.65,-2113.14)" :style="{fill: this.iconColor}">
            <g transform="matrix(2.375,0,0,5.33333,6494.6,938.566)">
                <g transform="matrix(0.581825,0,0,0.259094,114.548,219.715)">
                    <path d="M16,3.539C15.398,3.802 14.762,3.976 14.11,4.057C14.796,3.65 15.31,3.004 15.553,2.244C14.91,2.625 14.207,2.894 13.473,3.038C12.853,2.376 11.986,2 11.079,2C9.28,2 7.799,3.481 7.799,5.28C7.799,5.28 7.799,5.281 7.799,5.281C7.799,5.541 7.821,5.791 7.875,6.029C5.237,5.9 2.776,4.651 1.114,2.598C0.245,4.088 0.689,6.021 2.122,6.982C1.603,6.968 1.094,6.83 0.64,6.578L0.64,6.614C0.642,8.169 1.745,9.522 3.268,9.837C2.987,9.911 2.698,9.947 2.408,9.945C2.2,9.949 1.991,9.93 1.787,9.889C2.22,11.22 3.453,12.139 4.852,12.174C3.691,13.082 2.259,13.575 0.785,13.573C0.516,13.573 0.258,13.561 0,13.528C1.5,14.494 3.248,15.006 5.032,15C11.068,15 14.368,10 14.368,5.666C14.368,5.521 14.363,5.381 14.356,5.242C15.003,4.779 15.56,4.202 16,3.539Z" style="fill-rule:nonzero;"/>
                </g>
            </g>
        </g>
    </IconBase>
</template>

<script>
import IconBase from "./IconBase"

  export default {
    props: {
      width: {type: [Number, String],default: 20},
      height: {type: [Number, String],default: 20},
      iconColor: {type: String, default: 'currentColor'}
    },
    components: {
        IconBase
    }
  }
</script>