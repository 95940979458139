<script setup>
import IconMenuVue from './Icons/IconMenu.vue';
import IconCloseVue from './Icons/IconClose.vue';
import { onMounted, reactive } from 'vue';

const state = reactive({
    docked: true,
    menuOpen: false
})

function dockingManager() {
    if(window.scrollY == 0) {
        state.docked = true
    } else {
        state.docked = false
    }
}

onMounted(()=> {
    document.addEventListener('scroll', dockingManager);
})

</script>

<template>
    <div class="header" :class="{'header--undocked': !state.docked | state.menuOpen}">
        <a href="#" class="header__branding">
            <img class="header__branding__logo" src="../assets/Logo.svg">
        </a>
        <input v-model="state.menuOpen" type="checkbox" id="menu-controller" class="header__menu-controller" name="menu-controller">
        <nav class="header__navigation">
            <ul class="nav__items">
                <li class="nav__item" >
                    <a class="nav__item__link" href="#roadmap" >Roadmap</a>
                </li>
                <li class="nav__item" >
                    <a class="nav__item__link" href="roadmap.pdf" target="_blank" >Litepaper</a>
                </li>
                <li class="nav__item" >
                    <a class="nav__item__link" href="#team" >Team</a>
                </li>
                <li class="nav__item" >
                    <a class="nav__item__link" href="#faq" >FAQ</a>
                </li>
            </ul>
        </nav>
        <div class="header__cta">
            <label class="header__cta__menu-buttons" for="menu-controller">
                <Transition name="header__cta__menu-buttons" mode="out-in">
                    <IconCloseVue v-if="state.menuOpen"  height="36" width="36" />    
                    <IconMenuVue v-else height="36" width="36" />                
                </Transition>
            </label>
        </div>
        <!-- <div class="header__menu">

        </div> -->
    </div>
</template>

<style scoped>

    .header {
        position: fixed;
        top: 40px;
        left: 50%;

        transform: translateX(-50%);
        z-index: 2;
        background: hsla(var(--clr-base), .3);
        height: 60px;
        width: 100%;
        max-width: var(--max-width);
        border-radius: var(--border-radius);

        display: flex;
        justify-content: space-between;
        align-content: center;
        transition: background .3s, top .3s;
        /* outline: 1px solid hsla(var(--clr-base), 0); */
    }

    .header--undocked {
        top: 20px;
        background: linear-gradient(to right, hsla(var(--clr-gradient-blue), 1), hsla(var(--clr-gradient-green), 1), hsla(var(--clr-gradient-yellow), 1), hsla(var(--clr-gradient-pink), 1));
        /* outline: 1px solid hsla(var(--clr-base), 1); */
    }

    .header__branding {
        width: var(--margin);
        height: 60px;
        background-color: hsla(var(--clr-base), 1);
        border-radius: var(--border-radius);
        position: relative;
        transition: all .6s;
        display: inline-block;
    }

    .header--undocked .header__branding {
        background-color: hsla(var(--clr-base), .15);
    }

    .header__branding:hover {
        background-color: hsla(var(--clr-base), .5);
    }

    .header__branding:active {
        background-color: hsla(var(--clr-base), .65);
    }

    .header--undocked .header__branding:hover {
        background-color: hsla(var(--clr-base), .35);
    }

    .header--undocked .header__branding:active {
        background-color: hsla(var(--clr-base), .5);
    }

    .header__branding__logo {
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .6s;
    }

    .header--undocked .header__branding__logo {
        filter: invert(100%);
    }

    .header__navigation {
        
    }

    .nav__items {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .nav__item {
        list-style: none;
        white-space: nowrap;
        margin-right: 13px;
    }

    .nav__item:last-child {
        margin-right: 0;
    }

    .nav__item__link {
        color: hsl(var(--clr-primary));
        text-decoration: none;
        font-size: var(--fs-small);
        font-weight: 700;
        letter-spacing: 1px;
        display: inline-block;
        padding: 7px 13px;
        transition: opacity .3s, transform .3s, letter-spacing .5s;
        transform-origin: center;
    }

    .nav__item__link:hover {
        opacity: .7;
        letter-spacing: 3px;
    }

    .header--undocked .nav__item__link:hover {
        opacity: 1;
    }

    .nav__item__link:active {
        transform: perspective(700px) translateZ(-50px);
    }

    .header__cta {
        width: var(--margin);
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header__cta__menu-buttons {
        display: none;
    }

    .header__cta__menu-buttons-enter-active,
    .header__cta__menu-buttons-leave-active {
    transition: opacity 0.3s ease;
    }

    .header__cta__menu-buttons-enter-from,
    .header__cta__menu-buttons-leave-to {
    opacity: 0;
    }

    .header__menu-controller {
        display: none;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {

        .header__cta {
            display: none;
        }

        .header {
            padding-right: var(--border-radius);
        }

    }

    @media only screen and (min-width: 320px) and (max-width: 768px) {

        .header--undocked {
            background: linear-gradient(to right, hsl(var(--clr-gradient-yellow)), hsl(var(--clr-gradient-pink)));
        }

        .header__navigation {
            background: linear-gradient(to right, hsl(var(--clr-gradient-yellow)), hsl(var(--clr-gradient-pink)));
            width: 100%;
            border-radius: var(--border-radius);
            position: absolute;
            top: calc(100% + 20px);
            left: 0;
            visibility: hidden;
            transition: opacity .6s;
            opacity: 0;
            padding-top: var(--margin);
            padding-bottom: var(--margin);

        }

        .header__menu-controller:checked + .header__navigation {
            visibility: visible;
            opacity: 1;
        }

        .nav__items {
            flex-direction: column;
        }

        .nav__item {
            margin-right: 0;
        }

        .nav__item__link {
            text-align: left;
            text-transform: uppercase;
            font-weight: 900;
            font-size: var(--fs-h6);
        }

        .header__cta__menu-buttons {
            display: inline-block;
        }

    }



</style>