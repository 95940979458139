<template>
    <IconBase :width="width" :height="height" viewBox="0 0 24 24" icon-name="Menu">
        <rect x="0" y="0" width="24" height="24" style="fill:none;fill-rule:nonzero;"/>
        <path d="M3,18L21,18L21,16L3,16L3,18ZM3,13L21,13L21,11L3,11L3,13ZM3,6L3,8L21,8L21,6L3,6Z" style="fill-rule:nonzero;"/>
    </IconBase>
</template>

<script>
import IconBase from "./IconBase"

  export default {
    props: {
      width: {type: [Number, String],default: 20},
      height: {type: [Number, String],default: 20},
      iconColor: {type: String, default: 'currentColor'}
    },
    components: {
        IconBase
    }
  }
</script>